const initialState = { darktheme: true, role: "none" };
function ThemeReducer(state = initialState, action) {
  switch (action.type) {
    case "enableDark":
      return {
        darktheme: true,
      };
    case "disableDark":
      return {
        darktheme: false,
      };
    default:
      return state;
  }
}

export default ThemeReducer;
