const initialState = { login: false, role: 'none' }
function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case 'login':
      return {
        login: action.payload,
        // role: action.payload.role
      };
    default:
      return state;
  }
}

export default AuthReducer;
