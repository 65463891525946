import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Index from './Pages/Index'
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import AuthReducer from './redux/reducers/AuthReducer'
import AccountReducer from './redux/reducers/AccountReducer'
import ThemeReducer from './redux/reducers/ThemeReducer'
import DateReducer from "./redux/reducers/DateReducer"

const rootReducer = combineReducers({
  AuthReducer,
  AccountReducer,
  ThemeReducer,
  DateReducer
});

const store = createStore(rootReducer)
function App() {
  return (
    <Provider store={store}>
      <Index/>
    </Provider>
  );
}

export default App;
