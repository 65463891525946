import React, {useState, useEffect} from 'react'
import PageWrapper from '../../Layout/PageWrapper'
import { useSelector } from "react-redux";
import { API } from 'aws-amplify'
import backendConfig from '../../backendConfig'
import SLMDashboard from './SLMDashboard';
import { AmplifyCountryDialCode } from '@aws-amplify/ui-react';


const SLMView = () => {
  const accounts = useSelector(state => state.AccountReducer.accounts)
  const [dashboardUrl, setDashboardUrl] = useState(null)
  
  const loadDashboard = async () => {
    
    let dashboardId = '7da00258-0826-4b18-9656-10070921bcc7'
    
    if (window.location.hostname.includes('a2crover.aws.1cloudhub.com'))
      {dashboardId = '8ce672c7-a22f-4712-a4af-acdbb3960c50'}   
    console.log(dashboardId)

    if(dashboardId !== null){
      try {
        let data = await API.get(
          backendConfig.apiName,
          backendConfig.paths.getDashboards,
          {
            queryStringParameters: {
              dashboardId
            },
          }
        );
        console.log(data);
        setDashboardUrl(data.EmbedUrl);
      } catch (error) {
        console.log({ error });
      }
    } 
  }


  useEffect(() => {
    loadDashboard()
  
  },[accounts])

 
  
  return (
    
    <PageWrapper>
      <SLMDashboard dashboardUrl={dashboardUrl} />
    </PageWrapper>
    
    )
}


export default SLMView

