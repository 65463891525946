import React, {useState} from 'react'
import PageWrapper from "../../Layout/PageWrapper";
import { Auth } from "aws-amplify";

const Settings = () => {
  const [formData, setFormData] = useState({oldPassword: '', newPassword: ''})
  const [error, setError] = useState(false)
  const [errorValue, setErrorValue] = useState('')
  const [success, setSuccess] = useState(false)

  const changePassword = (e) => {
    e.preventDefault();
    setError(false)
    setSuccess(false)
    if (formData.oldPassword.length > 7 && formData.newPassword.length > 7){
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(
            user,
            formData.oldPassword,
            formData.newPassword
          );
        })
        .then((data) => {
          console.log(data)
          setSuccess(true)
        })
        .catch((err) =>{ 
          console.log(err)
          setErrorValue(err.message);
          setError(true)
        })
    }else{
      setErrorValue('Password character should be minimum 8')
      setError(true)
    }   
  }
  
  return (
    <PageWrapper>
      <div className="row">
        <div className="col"></div>
        <div className="col">
          <div className="card mt-5">
            <div className="card-body">
              <div
                className={
                  `alert alert-success ` + (success === false ? "d-none" : "")
                }
                role="alert"
              >
                Successfully Updated
              </div>
              <div
                className={
                  `alert alert-danger ` + (error === false ? "d-none" : "")
                }
                role="alert"
              >
                {errorValue}
              </div>
              <form>
                <div className="form-group">
                  <label htmlFor="oldPassword">Old Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="oldPassword"
                    value={formData.oldPassword}
                    onChange={(e) =>
                      setFormData({ ...formData, oldPassword: e.target.value })
                    }
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="newPassword">New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="newPassword"
                    value={formData.newPassword}
                    onChange={(e) =>
                      setFormData({ ...formData, newPassword: e.target.value })
                    }
                  ></input>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={changePassword}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="col"></div>
      </div>
    </PageWrapper>
  );
}

export default Settings
