import React, {useState, useEffect} from 'react'

const ConsolidatedDashboard = ({dashboardUrl}) => {
  const [dashboardHeight, setDashboardHeight] = useState(500)

  useEffect(() =>{
    let mainNavbarHeight = document.getElementById('main-navbar').offsetHeight;
    setDashboardHeight(window.innerHeight  - mainNavbarHeight)
  })

  return (
    <div
      className="bg-dark"
      style={{ height: `${dashboardHeight}px`, width: "auto" }}
    >
      <iframe
        src={dashboardUrl}
        title="Dashboard"
        width="100%"
        height={`${dashboardHeight}px`}
        />
    </div>
  )
}

export default ConsolidatedDashboard
