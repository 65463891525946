import React, {useEffect} from 'react'
import { StorageHelper } from "@aws-amplify/core";
import { Buffer } from "buffer";
import {Auth, API} from 'aws-amplify'
import backendConfig from '../../backendConfig'
const storage = new StorageHelper().getStorage();


let data = {
  id_token:
    "eyJraWQiOiJsbHBtaUhUV21NcGpROVUzeXhXT2RLdk82blcxKzY4NTJSeXNKclIxVGY0PSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoia1JnZXkzZlJEam5XQm5xUkZYRWI4ZyIsInN1YiI6ImZkODFlNGY1LWQ0NDgtNDhhYi05NzBlLWI3NTcyNjM1MjYyMCIsImNvZ25pdG86Z3JvdXBzIjpbImFwLXNvdXRoZWFzdC0xX0FPbmNtTkJodl9BV1MtU1NPLUFELUNvbm5lY3Rvci1BdXRoIl0sImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoZWFzdC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoZWFzdC0xX0FPbmNtTkJodiIsImNvZ25pdG86dXNlcm5hbWUiOiJBV1MtU1NPLUFELUNvbm5lY3Rvci1BdXRoX2VtYWlsIiwiYXVkIjoiNmE5cWRhZGYzM3Z2cWQ4MTJiNGhpNmdyNzEiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJlbWFpbCIsInByb3ZpZGVyTmFtZSI6IkFXUy1TU08tQUQtQ29ubmVjdG9yLUF1dGgiLCJwcm92aWRlclR5cGUiOiJTQU1MIiwiaXNzdWVyIjoiaHR0cHM6XC9cL3BvcnRhbC5zc28uYXAtc291dGhlYXN0LTEuYW1hem9uYXdzLmNvbVwvc2FtbFwvYXNzZXJ0aW9uXC9NamN5T0RVNE5EZzRORE0zWDJsdWN5MDVOR1ppT1RVeFpXWTNNell5WlRrMCIsInByaW1hcnkiOiJ0cnVlIiwiZGF0ZUNyZWF0ZWQiOiIxNjAwNzc0NDgwNDk3In1dLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYwMDc5MDg4MywibmFtZSI6IkZyYW5rIEQiLCJleHAiOjE2MDA3OTQ0ODMsImlhdCI6MTYwMDc5MDg4MywiZW1haWwiOiJmcmFua0AxY2xvdWRodWIuY29tIn0.og6eZxX55xmJusBviT9SnUPXhYtJXAnr5_uGO45ZQWd4K90Lxe2-vFGd2GjKg_c9ORco9Sxz85oZj618mZVAiUo_Mte-kNIb0Q3A5S6CmOKKik4uBmFmhYrdAok9X8UlvODAXRt59k1Gi1jdziHMuKHesXh0-lLxS5RFwOO7kMtXP84fLoFuRtF1jEIdmfEI60Y8_jCRxCiNEZ_ChWGpiEbmJFA3sergXXT9GmgDu5NJ31nlI4ggz8HikAr9r28Bcwy0SNtSs3cgghNCOfCiPnEMtJYyEMQ5vAk8WZE7_Kn7tvVfzylq9xodnkp3zC9zfHr6IrkWts8ildsU7F0MsA",
  access_token:
    "eyJraWQiOiJtWjJGb2h1bjIrc0JXSzhUMGRiQ2x6dnYwUzc0T2g4dFwvNW1jaVNBQkkxYz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJmZDgxZTRmNS1kNDQ4LTQ4YWItOTcwZS1iNzU3MjYzNTI2MjAiLCJjb2duaXRvOmdyb3VwcyI6WyJhcC1zb3V0aGVhc3QtMV9BT25jbU5CaHZfQVdTLVNTTy1BRC1Db25uZWN0b3ItQXV0aCJdLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIHBob25lIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNjAwNzkwODgzLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGhlYXN0LTEuYW1hem9uYXdzLmNvbVwvYXAtc291dGhlYXN0LTFfQU9uY21OQmh2IiwiZXhwIjoxNjAwNzk0NDgzLCJpYXQiOjE2MDA3OTA4ODMsInZlcnNpb24iOjIsImp0aSI6ImNhYzQyYWRkLTk5ZTItNDJiYS1iY2I1LTEzY2I3MjIyYTJmYyIsImNsaWVudF9pZCI6IjZhOXFkYWRmMzN2dnFkODEyYjRoaTZncjcxIiwidXNlcm5hbWUiOiJBV1MtU1NPLUFELUNvbm5lY3Rvci1BdXRoX2VtYWlsIn0.dbg_P2wfJpjvZXYgWv7Vr2i1vnNlhvEPwJfRMgEIoGiuKv6vg6IMz-BDQSGwW6ghn41swdRUzE_stETMY_ayfwOZeeic3UC-MKK_-y9Av5fKhvnLW18yRLA5AolD6cJB9nnbyv5j1XVvHPGpG4taU0-IW9ciFUCLv8QzafPd_gLadxyeGNZHUeWKB6zWuOi5hyo51kEj3aVlSKzsmqTw1Lyuo_uglHMuJDz3J_ka19fcwGU7cYPJ3b4QbvUE90v2ADi6ibh5jfcZBZTznDGrAJnTQv59BkrPfkW9m1PgQhhT3zI4Hdb93EMHmZR2Y1md0a2tG7uSad1dOgo_js5o9g",
  refresh_token:
    "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.AvnOhwmKO81Pkp6Ua16nNCgX-9AXZYBMAsUlLfNrPfyLYh_5e1YpL3edxY3Njf-opozUS3r2sKxhZN_Z0q6Wokd41tjoSQTzOSh_wQnMlBptbln6z-28iTnvu6Xm8_qnFRwTbx-MxvKaBF5-7HUECnrjN3CwoR2KN5cHrJTnzpcYsL3pQX11aPmpQqUqoQXSKuPgMMla7W_OvHf3LiKIvpoxW4iBt5ErQSU806lToeNY8C_Zv3xToPa6lzbjZqUQXlYftjuqIG8xxJQN3Za7zQibf3JNUUWJXxBRDp05bhTLnFwH1kJzdCSR0oDyvd6yOah_ekrsRhj-B510dY9NNg.SYv43Hfju4gIalKP.8goNbE2-gRGilWTurDhmr4UDwifezgYQ8oWcM7nKFaALF2yiQ6Ad7aht-0OmmkQOsMCjxIcEhTL6Om0khK5NzLHyYT8ubpA6gSGBfAPJomh9C5OdWbabkz0vAayKYJrPZrAPEDrQxI4lg6iQx9t97HPaKxxcgYO1tqVTIu6j9A_Arq99Xe53NC2h8IIdRZW2ftyScN0tkXO0QFl0QzC3rz3rCGqISos_TXWfpZiPrObaTq21K7ub-XVQdH5cT-FclCYLJXhb_h8oWnffwwerk8zxdnhazi46oxUxNmz8y0lGsPx--V3bHpsymySmLnP_OyCiL9R_Fz31TvfjvzBeIoCTquQ67bex9kkP0ukg76g4AdQ5FJDEtqGx1hS0jZmto-DIE5U1TqLlWCb__WsMP0d8O708E0Qwz5pvfrHZ5OaKmOlYk5wiIQeuaCz0-uHNdWES7z2Bf61cNnKvmIz7mO8tFTkiOkHloqOWcQk783cF2eGsmj8ur7rjhQ_WppXj4Kzez2YB1cuTbg7eGj28RniN3Rha22GRXbOfD3zcVAWmqYiaG8Gw-F5sxw7D3FGajpksKc41SGHI7pJrouyopcvFwU6aVQTJX0wd-C5EpR9G2LFOsvBsx-NhZu-B3pke7s0_n6mFh3VVHYeBivGxDb10hn7ThSvmbw0d9n8Igeh7xG__7PDEn5XpFX5yB2_DYUl2pJInaFQ-G4qVSJ7E9Kzu9fllHHJTE-0xxz_5yMvXox2ymgRDh6Nm1dN6LkMXT3mlFRucqCeRWrWuC9IVYHATHs2qwxkj7TUm5U6l7SD89bCmivJemnhyHVFz6QOtWus1X-zHEI4GI-tlS1vOWMv47E5bON_gHavrwSXXdIU9Gb9WOwOFQAzo78nx_Kawbs20Ti5pamQXK7wPZjx3Svlgc7puWsFBDn7Gb2HA8hqsyf4tmRPich4422Y_NDieSL27EqzhyqHxSnwcjrxc1mVEkBgE7LeU8L4hlCbIGXnfrIURGINqQQ1BKGPzTH4N5ZYXNFmN1mi7oe5oPyMtCSQs9MbEsMzWT6mZpM_3MeisP0GUirTtdVYm7DYe4wIr2jGsygeCMpdHg3m-P40A_El5PVI7b1nDEaR7oJLOV72E2gwAXu1fv0UGc7b6kGBb8vKQohTLPmcHOcj5No6xbnnBYbjBjlr66Z3IRjLxQ4enueqVtUfeGN1YNMcJED9SdX6gSh4pHMSAtylRDRe1jBJSOtk_gbS0mQ7F5yMDHT2lExGkECMZunfhUErA7EtJGWOg6Skm1EDapltoEqCEDxB2qwq4iN7Xl1FRcef1Iz0Qqco.iYhL0OWCHiGMrMkcYu9vnw",
  expires_in: 3600,
  token_type: "Bearer",
};

const ADAuth = () => {
  const decodePayload = (jwtToken) => {
    const payload = jwtToken.split(".")[1];
    try {
      return JSON.parse(Buffer.from(payload, "base64").toString("utf8"));
    } catch (err) {
      return {};
    }
  };
  const calculateClockDrift = (iatAccessToken, iatIdToken) => {
    const now = Math.floor(new Date() / 1000);
    const iat = Math.min(iatAccessToken, iatIdToken);
    return now - iat;
  };
  useEffect(() => {
    // const idTokenData = decodePayload(data["id_token"]);
    // const accessTokenData = decodePayload(data["access_token"]);
    // storage.setItem(
    //   "CognitoIdentityServiceProvider." + "6a9qdadf33vvqd812b4hi6gr71" + ".LastAuthUser",
    //   idTokenData["email"]
    // );
    // storage.setItem(
    //   "CognitoIdentityServiceProvider." +
    //     "6a9qdadf33vvqd812b4hi6gr71" +
    //     "." +
    //     idTokenData["email"] +
    //     ".idToken",
    //   data["id_token"]
    // );
    // storage.setItem(
    //   "CognitoIdentityServiceProvider." +
    //     "6a9qdadf33vvqd812b4hi6gr71" +
    //     "." +
    //     idTokenData["email"] +
    //     ".accessToken",
    //   data["access_token"]
    // );
    // storage.setItem(
    //   "CognitoIdentityServiceProvider." +
    //     "6a9qdadf33vvqd812b4hi6gr71" +
    //     "." +
    //     idTokenData["email"] +
    //     ".refreshToken",
    //   data["refresh_token"]
    // );
    // storage.setItem(
    //   "CognitoIdentityServiceProvider." +
    //     "6a9qdadf33vvqd812b4hi6gr71" +
    //     "." +
    //     idTokenData["email"] +
    //     ".clockDrift",
    //   "" + calculateClockDrift(accessTokenData["iat"], idTokenData["iat"]) + ""
    // );
    Auth.federatedSignIn(
      {provider:"developer"},
      // {
      //   token:
      //     "eyJraWQiOiJsbHBtaUhUV21NcGpROVUzeXhXT2RLdk82blcxKzY4NTJSeXNKclIxVGY0PSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoiQjNla0VqZmxvdi1lSHE3dVJBMHM0USIsInN1YiI6ImZkODFlNGY1LWQ0NDgtNDhhYi05NzBlLWI3NTcyNjM1MjYyMCIsImNvZ25pdG86Z3JvdXBzIjpbImFwLXNvdXRoZWFzdC0xX0FPbmNtTkJodl9BV1MtU1NPLUFELUNvbm5lY3Rvci1BdXRoIl0sImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoZWFzdC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoZWFzdC0xX0FPbmNtTkJodiIsImNvZ25pdG86dXNlcm5hbWUiOiJBV1MtU1NPLUFELUNvbm5lY3Rvci1BdXRoX2VtYWlsIiwiYXVkIjoiNmE5cWRhZGYzM3Z2cWQ4MTJiNGhpNmdyNzEiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJlbWFpbCIsInByb3ZpZGVyTmFtZSI6IkFXUy1TU08tQUQtQ29ubmVjdG9yLUF1dGgiLCJwcm92aWRlclR5cGUiOiJTQU1MIiwiaXNzdWVyIjoiaHR0cHM6XC9cL3BvcnRhbC5zc28uYXAtc291dGhlYXN0LTEuYW1hem9uYXdzLmNvbVwvc2FtbFwvYXNzZXJ0aW9uXC9NamN5T0RVNE5EZzRORE0zWDJsdWN5MDVOR1ppT1RVeFpXWTNNell5WlRrMCIsInByaW1hcnkiOiJ0cnVlIiwiZGF0ZUNyZWF0ZWQiOiIxNjAwNzc0NDgwNDk3In1dLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYwMDc5NTkxNCwibmFtZSI6IkZyYW5rIEQiLCJleHAiOjE2MDA3OTk1MTQsImlhdCI6MTYwMDc5NTkxNCwiZW1haWwiOiJmcmFua0AxY2xvdWRodWIuY29tIn0.VFwRElQaDu4j1_9puLbR2LRvHHnAA-MVEm4q_4-48Tk7h0bCQzD_qX0QOS2XWaVlU__AKfhNM8vcFlrBhApmFzy7FcOuyeXJHNkxfiTT9WnFpcp3VI09On8HtewZoZhIvxJQ0OWmun4D5oScGGSrBhIHlXm2mxol4bfo1i4f3k950-2oiqcQ9Af6YjsmxJr2puvg5EixRB6mcwaUXwGV3fUG3jXchyzxOdUIh2LQVrrIoylG2vhCmeI8bv8G9HKyHRaxVgB54qDzQtty6X_aNuFYLm7_BXLPGij74dCpaCzYcj85Om9cCfIrJikn1-c0BQIZpibfwL2zmFqidX9m7w",
      //   // expires_at: new Date().getTime() + 3600000, // the expiration timestamp
      // },
      // { email: "frank@1cloudhub.com" }
    )
      .then((cred) => {
        // If success, you will get the AWS credentials
        console.log(cred);
        return Auth.currentAuthenticatedUser();
      })
      .then((user) => {
        // If success, the user object you passed in Auth.federatedSignIn
        console.log(user);
      })
      .catch((e) => {
        console.log(e);
      });
    // let func = async () => {
    //     let data = await API.get(
    //       backendConfig.apiName,
    //       backendConfig.paths.getAccounts,
    //       {
    //         headers: {
    //           Authorization: `Bearer eyJraWQiOiJsbHBtaUhUV21NcGpROVUzeXhXT2RLdk82blcxKzY4NTJSeXNKclIxVGY0PSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoiQjNla0VqZmxvdi1lSHE3dVJBMHM0USIsInN1YiI6ImZkODFlNGY1LWQ0NDgtNDhhYi05NzBlLWI3NTcyNjM1MjYyMCIsImNvZ25pdG86Z3JvdXBzIjpbImFwLXNvdXRoZWFzdC0xX0FPbmNtTkJodl9BV1MtU1NPLUFELUNvbm5lY3Rvci1BdXRoIl0sImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoZWFzdC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoZWFzdC0xX0FPbmNtTkJodiIsImNvZ25pdG86dXNlcm5hbWUiOiJBV1MtU1NPLUFELUNvbm5lY3Rvci1BdXRoX2VtYWlsIiwiYXVkIjoiNmE5cWRhZGYzM3Z2cWQ4MTJiNGhpNmdyNzEiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJlbWFpbCIsInByb3ZpZGVyTmFtZSI6IkFXUy1TU08tQUQtQ29ubmVjdG9yLUF1dGgiLCJwcm92aWRlclR5cGUiOiJTQU1MIiwiaXNzdWVyIjoiaHR0cHM6XC9cL3BvcnRhbC5zc28uYXAtc291dGhlYXN0LTEuYW1hem9uYXdzLmNvbVwvc2FtbFwvYXNzZXJ0aW9uXC9NamN5T0RVNE5EZzRORE0zWDJsdWN5MDVOR1ppT1RVeFpXWTNNell5WlRrMCIsInByaW1hcnkiOiJ0cnVlIiwiZGF0ZUNyZWF0ZWQiOiIxNjAwNzc0NDgwNDk3In1dLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYwMDc5NTkxNCwibmFtZSI6IkZyYW5rIEQiLCJleHAiOjE2MDA3OTk1MTQsImlhdCI6MTYwMDc5NTkxNCwiZW1haWwiOiJmcmFua0AxY2xvdWRodWIuY29tIn0.VFwRElQaDu4j1_9puLbR2LRvHHnAA-MVEm4q_4-48Tk7h0bCQzD_qX0QOS2XWaVlU__AKfhNM8vcFlrBhApmFzy7FcOuyeXJHNkxfiTT9WnFpcp3VI09On8HtewZoZhIvxJQ0OWmun4D5oScGGSrBhIHlXm2mxol4bfo1i4f3k950-2oiqcQ9Af6YjsmxJr2puvg5EixRB6mcwaUXwGV3fUG3jXchyzxOdUIh2LQVrrIoylG2vhCmeI8bv8G9HKyHRaxVgB54qDzQtty6X_aNuFYLm7_BXLPGij74dCpaCzYcj85Om9cCfIrJikn1-c0BQIZpibfwL2zmFqidX9m7w`,
    //         },
    //         queryStringParameters: {
    //           email: "frank@1cloudhub.com",
    //         },
    //       }
    //     );
    //     console.log(data)
    // }
    // func()
  },[])
  return (
    <div>
      
    </div>
  )
}

export default ADAuth
