import React, {useState, useEffect} from 'react'

const MigrateDashboard = ({dashboardUrl}) => {
  const [dashboardHeight, setDashboardHeight] = useState(500)

  useEffect(() =>{
    let mainNavbarHeight = document.getElementById('main-navbar').offsetHeight;
    setDashboardHeight(window.innerHeight  - mainNavbarHeight)
  })

  return (
    <>
    <div id="dashboard-menu">
        <div className="row align-items-center dark-theme-tab " style={{margin: "auto", marginRight: "0"}}>
        <div className="col mt-1 mb-1">&nbsp;</div>
          <div style={{float: "right"}}>
            <div style={{fontSize: "13px", color: "white", paddingRight: "20px", float: "right"}}>
              Powered By 1Cloudhub</div>
          </div>
        </div>
    </div>
    <div
      className="bg-dark"
      style={{ height: `${dashboardHeight}px`, width: "auto" }}
    >
      <iframe
        src={dashboardUrl}
        title="Dashboard"
        width="100%"
        height={`${dashboardHeight}px`}
        />
    </div>
    </>
  )
}

export default MigrateDashboard
