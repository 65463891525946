import React, { useEffect, useState } from 'react'
import { a, Auth } from "aws-amplify";
import {
  useSelector,
  useDispatch
} from "react-redux";
import { useHistory } from 'react-router-dom'
import { Link, NavLink } from 'react-router-dom'
import { Navbar } from 'react-bootstrap'
import './NavBar.css'

const NavBar = () => {
  let history = useHistory();
  const loginState = useSelector(state => state.AuthReducer.login)
  const darkTheme = useSelector((state) => state.ThemeReducer.darktheme);
  const dispatch = useDispatch()
  const search = window.location.search;
  const href = window.location.href;
  const params = new URLSearchParams(search);
  const accountName = params.get("accountName");
  const cloudPlatform = params.get("cloudPlatform");

  const accounts = useSelector(state => state.AccountReducer.accounts?.dark?.L || [])
  // console.log(accounts)
  const user = useSelector(state => state.AccountReducer.name.charAt(0).toUpperCase() + state.AccountReducer.name.slice(1))
  const [currentAccountName, setCurrentAccountname] = useState({ name: 'All Accounts' })
  const [viewIsActive, setViewIsActive] = useState('')

  const findNthOccurence = (string, nth, char) => {
    let index = 0
    for (let i = 0; i < nth; i += 1) {
      if (index !== -1) index = string.indexOf(char, index + 1)
    }
    return index
  }

  useEffect(() => {
    console.log("accname", accountName);
    if (accounts.length) {
      if (accountName !== null) {
        setCurrentAccountname({
          name: accountName,
          cloud: cloudPlatform,
        });
      } else {
        let currentUrlPath = window.location.pathname;
        if (currentUrlPath.includes("/account/")) {
          let secondIndex = findNthOccurence(currentUrlPath, 1, "/") + 1;
          let accountId = currentUrlPath.slice(
            secondIndex,
            currentUrlPath.length
          );
          if (accountId.includes("/")) {
            accountId = accountId.replace("/", "");
          }
          if (accounts[accountId]) {
            setCurrentAccountname({
              name: accounts[accountId].M.name.S,
              cloud: accounts[accountId].M.cloud.S,
            });
          }
        }
      }
    }
  }, [accounts]);
  // console.log(loginState)

  const handleAuthStateChange = async () => {
    try {
      await Auth.signOut()
      dispatch({ type: "login", payload: false })
      history.push("/");
    } catch (error) {
      console.log('sorrry couldn\'t able to logout')
    }
  }
  const handleTheme = () => {
    if (darkTheme) {
      dispatch({ type: "disableDark" })
    } else {
      dispatch({ type: "enableDark" })
    }
    console.log('dark theme enabled', darkTheme)
  }
  console.log('HREF: ',{href})
  const words = ['/assess','/migrate','/operate','/slm']
  function checkURL(input, words) {
    return words.some(word => input.toLowerCase().includes(word.toLowerCase()));
   }
  return (
  <>
    <div id="main-navbar">
      <nav className="navbar navbar-custom navbar-expand-lg ">

        <Navbar.Brand style={{ padding: 0 }}>
          <a href="/">
            <img
              src="/images/logos/a2c_rover.png"
              height="42"
              className="d-inline-block align-top"
              alt=""
              loading="lazy"
              style={{ borderRadius: "1%" }}
            />
          </a>
        </Navbar.Brand>

        {loginState === true && (
          <>
          <ul class="navbar-nav" style={{paddingLeft:"4em"}}>
            <li class="nav-item">
                <a class="nav-link" href="#">My Dashboard</a>
            </li>
          
            <li class="nav-item">          
               {(checkURL(href,['/assess']) === true) ? 
                (<a
                  class="nav-link dark-active" 
                  href="/assess">Assess</a>):
                (<a
                  class="nav-link" 
                  href="/assess">Assess</a>)}
            </li>
          
            <li class="nav-item">
            {(checkURL(href,['/migrate']) === true) ? 
                (<a
                  class="nav-link dark-active" 
                  href="/migrate">Migrate</a>):
                (<a
                  class="nav-link" 
                  href="/migrate">Migrate</a>)}
            </li>
          
            <li class="nav-item">
            {(checkURL(href,['/operate']) === true) ? 
                (<a
                  class="nav-link dark-active" 
                  href="/operate">Operate</a>):
                (<a
                  class="nav-link" 
                  href="/operate">Operate</a>)}
            </li>

            <li class="nav-item">
            {(checkURL(href,['/slm']) === true) ? 
                (<a
                  class="nav-link dark-active" 
                  href="/slm">SLM</a>):
                (<a
                  class="nav-link" 
                  href="/slm">SLM</a>)}
            </li>
            <li class="nav-item">
              {(checkURL(href, words) === false) ? 
                  (<a
                    class="nav-link dark-active" 
                    href="/consolidated-view">Costimize</a>):
                  (<a
                    class="nav-link" 
                    href="/consolidated-view">Costimize</a>)}
                {/* <a class="nav-link dark-active" href="/consolidated-view">Costimize</a> */}
            </li>
        </ul>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto" >
              {(checkURL(href, words) === false) ? (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ color: "white", fontSize: "15px" }}
                  >
                    {currentAccountName?.cloud &&
                      currentAccountName.cloud === "aws" ? (
                      <img
                        src="/images/logos/aws_black_baground.png"
                        style={{ width: "26px", marginRight: "6px" }}
                      ></img>
                    ) : (
                      ""
                    )}
                    {currentAccountName?.cloud &&
                      currentAccountName.cloud === "azure" ? (
                      <img
                        src="/images/logos/azure.png"
                        style={{ width: "14px", marginRight: "6px" }}
                      ></img>
                    ) : (
                      ""
                    )}
                    {currentAccountName.name}
                  </a>

                  <div
                    id="dropdown-menu"
                    className="text-center dropdown-menu dropdown-menu-right"
                    aria-labelledby="navbarDropdown"
                    style={{ marginTop: "-8px" }}
                  >
                    <div className="dropdown-item">
                      <Link
                        to={"/consolidated-view"}
                        style={{ marginRight: "80%", fontSize: "14px" }}
                      >
                        <span>All Accounts</span>
                      </Link>
                    </div>
                    {accounts.length ? (
                      accounts.map((account, index) => (
                        <div key={index}>
                          <div className="dropdown-item">
                            <Link
                              to={"/account/" + index}
                              style={{ marginRight: "80%", fontSize: "14px" }}
                            >
                              {account.M.cloud.S === "aws" ? (
                                <img
                                  src="/images/logos/aws_black_baground.png"
                                  style={{ width: "18px", marginRight: "10px" }}
                                ></img>
                              ) : (
                                <img
                                  src="/images/logos/azure.png"
                                  style={{ width: "12px", marginRight: "10px" }}
                                ></img>
                              )}
                              <span>{account.M.name.S}</span>
                            </Link>
                          </div>
                          {accounts.length - 1 !== index && (
                            <div className="dropdown-divider"></div>
                          )}
                        </div>
                      ))
                    ) : (
                      <a
                        className="dropdown-item"
                        href="#"
                        style={{ fontSize: "14px" }}
                      >
                        Loading
                      </a>
                    )}
                  </div>

                </li> ) :
                ('')}

                <li className="nav-item dropdown" style={{ padding: 0 }}>
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ color: "white", fontSize: "15px" }}
                  >
                    {user}
                  </a>

                  <div
                    id="dropdown-menu"
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="navbarDropdown"
                    style={{ marginTop: "-8px" }}
                  >
                    <div className="dropdown-item">
                      <NavLink to={`/settings`} style={{ fontSize: "14px" }}>
                        Settings
                      </NavLink>
                    </div>

                    <div className="dropdown-divider"></div>
                    <div className="dropdown-item">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleAuthStateChange(e);
                        }}
                        style={{ fontSize: "14px" }}
                      >
                        Signout
                      </a>
                    </div>


                  </div>

                </li>

              </ul>

            </div>

          </>

        )}

      </nav>

    </div>

    {/* { loginState === true && ( 
      <>
        <div >
          <nav className="navbar navbar-custom-1 navbar-expand-lg ">
            <ul class="navbar-nav navbar-nav-1" style={{paddingLeft:"14em"}}>
              <li class="nav-item">
                  <a class="nav-link" href="#">Assets</a>
              </li>

              <li class="nav-item">   
                  <a class="nav-link" href="#">Analysis</a>
              </li>
            
              <li class="nav-item">
                  <a class="nav-link" href="#">Planning</a>
              </li>
            </ul>
            <span style={{position:"absolute", paddingRight:"25px", right:0,
            color: "white",  
            fontFamily: "Century Gothic,CenturyGothic,AppleGothic,sans-serif", 
            fontSize:"0.6em"}}>Powered by 1Cloudhub</span>
            </nav>
            </div>
            </>
          )} */}
  </>
  );
}

export default NavBar
