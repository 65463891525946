const initialState = { accounts: [], name: 'User'}
function AccountReducer(state = initialState, action) {
  switch (action.type) {
    case 'gotAccounts':
      return {
        accounts: action.payload.accounts,
        name: action.payload.name
      };
    default:
      return state;
  }
}

export default AccountReducer;