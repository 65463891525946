import React from 'react'
import NavBar from './NavBar'

const PageWrapper = (props) => {
  return (
    <div>
    <NavBar/>
      {props.children}
    </div>
  )
}

export default PageWrapper