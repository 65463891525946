import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const ViewAccount = () => {
  // https://costimize.1cloudhub.com/view-account/?account_id=45761564826&cloudPlatform=''
  const accounts = useSelector(state => state.AccountReducer.accounts)
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const account_id = params.get('account_id');
  let history = useHistory();

  useEffect(() => {
    // console.log('hi')
    let count = 0
    console.log(accounts)
    if(accounts?.dark?.L){
      for( let account of accounts.dark.L){
        if(account.M.account_id.S === account_id){
          // window.location.href = window.location.origin + '/account/' + count
          // window.location.replace(window.location.origin + '/account/' + count)
          history.push('/account/'+count)
          break
        }
        count ++
      } 
    }   
  },[accounts])

  return (
    <div>
      
    </div>
  )
}

export default ViewAccount
