import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Amplify, { Auth } from "aws-amplify";
import awsconfig from './aws-exports';
import { StorageHelper } from "@aws-amplify/core";
const storage = new StorageHelper().getStorage();

Amplify.configure(awsconfig);
Auth.configure({
  
  storage: storage,
  // oauth: {
  //   domain: "costimize-1cloudhub-com.auth.ap-southeast-1.amazoncognito.com",
  //   scope: [
  //     "phone",
  //     "email",  
  //     "profile",
  //     "openid",
  //     "aws.cognito.signin.user.admin",
  //   ],
  //   redirectSignIn: process.env.REACT_APP_oAuthRedirectUrl, //taken from env variable
  //   redirectSignOut: process.env.REACT_APP_oAuthSignOutUrl, //taken from env variable
  //   responseType: "code",
  //   client_id: "6a9qdadf33vvqd812b4hi6gr71",
  // },
});


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
