import React, {useEffect} from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Auth from './Auth/Auth'
import Settings from './Settings/Settings'
import ViewDashboard from './ViewDashboard/ViewDashboard'
import Accounts from './Accounts/Accounts'
import Account from './Account/Account'
import ViewRuleDetail from "./ViewRuleDetail/ViewRuleDetail";
import ViewInstanceUtilization from "./ViewInstanceUtilization/ViewInstanceUtilization";
import ConsolidatedView from './ConsolidatedView/ConsolidatedView'
import AssessView from './AssessView/AssessView'
import MigrateView from './MigrateView/MigrateView'
import OperateView from './OperateView/OperateView'
import SLMView from './SLMView/SLMView'
import ViewAccount from './ViewAccount/ViewAccount'
import ADAuth from './ADAuth/ADAuth'
import {API, Auth as AmpAuth} from 'aws-amplify'
import {
  useDispatch 
} from "react-redux";
import backendConfig from  '../backendConfig'
const createHistory = require("history").createBrowserHistory
const history = createHistory();

const Index = () => {
  const dispatch = useDispatch();
  // const dispatchAccounts = useDispatch()

  useEffect(() => {
    let func = async () => {
      try {
        let login = await AmpAuth.currentAuthenticatedUser()
        const currentUserInfo = await AmpAuth.currentUserInfo();
        const email = currentUserInfo.attributes.email;
        console.log(currentUserInfo)
        const name = email.slice(0, email.indexOf('@'))
        let data = await API.get(
          backendConfig.apiName,
          backendConfig.paths.getAccounts,
          {
            queryStringParameters: {
              email
            },
          }
        );
        console.log(data.message.Item.Accounts.M);
        dispatch({ type: "login", payload: true })
        dispatch({
          type: "setDate",
          payload: {
            date: data.message.Item.Accounts.M.maxDate.S,
            minDate: data.message.Item.Accounts.M.minDate.S,
            maxDate: data.message.Item.Accounts.M.maxDate.S,
          },
        });
        dispatch({ type: 'gotAccounts', payload: { accounts: data.message.Item.Accounts.M, name: name } })        
      } catch (error) {
        dispatch({ type: "login", payload: false })
      }
    }
    func()
  }, [])

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <Auth />
        </Route>
        <Route path="/consolidated-view">
          <ConsolidatedView/>
        </Route>
        <Route path="/assess">
          <AssessView/>
        </Route>
        <Route path="/migrate">
          <MigrateView/>
        </Route>
        <Route path="/operate">
          <OperateView/>
        </Route>
        <Route path="/slm">
          <SLMView/>
        </Route>
        <Route path="/view-account">
          <ViewAccount/>
        </Route>        
        <Route path="/settings" exact>
          <Settings />
        </Route>
        <Route path="/dashboard" exact>
          <ViewDashboard />
        </Route>
        <Route path="/accounts" exact>
          <Accounts />
        </Route>
        <Route path="/ad-auth" exact>
          <ADAuth/>
        </Route>
        <Route
          path="/account/:accountId"
          component={(props) => (
            <Account key={window.location.pathname} {...props} />
          )}
          exact
        />
        <Route path="/view_rule_detail" component={ViewRuleDetail} exact />
        <Route
          path="/view_instance_utilization"
          component={ViewInstanceUtilization}
          exact
        />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default Index
