import React, {useState, useEffect} from 'react'
import PageWrapper from '../../Layout/PageWrapper'
import { useSelector } from "react-redux";
import { API } from 'aws-amplify'
import backendConfig from '../../backendConfig'
import AssessDashboard from './AssessDashboard';


const AssessView = () => {
  const accounts = useSelector(state => state.AccountReducer.accounts)
  const [dashboardUrl, setDashboardUrl] = useState(null)
  
  const loadDashboard = async () => {
    let dashboardId = 'a99fb5b0-9ffa-480d-bccb-6b807ccb91da'

    if (window.location.hostname.includes('a2crover.aws.1cloudhub.com'))
      {dashboardId = '336cf7a0-89ff-4048-9519-c3d094bcc61d'}   
    console.log(dashboardId)

    if(dashboardId !== null){
      try {
        let data = await API.get(
          backendConfig.apiName,
          backendConfig.paths.getDashboards,
          {
            queryStringParameters: {
              dashboardId
            },
          }
        );
        console.log(data);
        setDashboardUrl(data.EmbedUrl);
      } catch (error) {
        console.log({ error });
      }
    } 
  }


  useEffect(() => {
    loadDashboard()
  
  },[accounts])

 
  
  return (
    
    <PageWrapper>
      <AssessDashboard dashboardUrl={dashboardUrl} />
    </PageWrapper>
    
    )
}


export default AssessView
