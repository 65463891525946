// const initialState = { date: new Date().toISOString().substr(0, 7) };
const initialState = {
  date: "2020-10",
  minDate: new Date().toISOString().substr(0, 7),
  maxDate: new Date().toISOString().substr(0, 7)
};
function DateReducer(state = initialState, action) {
  switch (action.type) {
    case "changeDate":
      return {
        ...state,
        date: action.payload.date,
      };
    case "setDate":{
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
}

export default DateReducer;