import React, { useState, useEffect } from "react";
import PageWrapper from '../../Layout/PageWrapper'
import { useSelector } from "react-redux";
import { API } from 'aws-amplify'
import backendConfig from '../../backendConfig'
import ViewDashboard from '../..//Views/ViewDashboard'

const Accounts = () => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');
  const accounts = useSelector(state => state.AccountReducer.accounts)  
  const [dashboardId, setDashboardId] = useState(id)
  const [dashboardUrl, setDashboardUrl] = useState(null)
  const [accountName, setAccountName] = useState('')
  const [currentAccountDetail, setCurrentAccountDetail] = useState()

  let func = async (dashboardId) => {
    try {
      let data = await API.get(
        backendConfig.apiName,
        backendConfig.paths.getDashboards,
        {
          queryStringParameters: {
            dashboardId,
          },
        }
      );
      console.log({ data });
      setDashboardUrl(data.EmbedUrl);
      // console.log(dashboardId)
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (dashboardId === null && accounts.length){    
      setCurrentAccountDetail(accounts[0])
      func(accounts[0].M.report.S)
      setAccountName(accounts[0].M.name.S)
      console.log(accounts)
    } else if (dashboardId !== null){
      func(dashboardId)
    }
  }, [accounts]) 

  return (
    <PageWrapper>
      <PageWrapper>
        <ViewDashboard currentAccountDetail={currentAccountDetail} dashboardUrl={dashboardUrl} accountName={accountName} />
      </PageWrapper>      
    </PageWrapper>
  );
}

export default Accounts