import React, {useEffect, useState} from 'react'
import './ViewDashboard.css'
import DatePicker from 'react-datepicker'
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
require("react-datepicker/dist/react-datepicker.css");

const ViewDashboard = ({
  darkTheme,
  showSavingTracker,
  activeReport,
  loadDashboard,
  currentAccountDetail,
  dashboardUrl,
  accountName,
  date
}) => {
  const dispatch = useDispatch();
  const [dashboardHeight, setDashboardHeight] = useState(500)
  const minMaxDate = useSelector((state) => state.DateReducer);
  console.log('hi',minMaxDate);
  
  const [startDate, setStartDate] = useState('');

  useEffect(() =>{
    // console.log('running')
    let mainNavbarHeight = document.getElementById('main-navbar').offsetHeight;
    let dashboardMenuHeight = document.getElementById('dashboard-menu').offsetHeight;
    setDashboardHeight(window.innerHeight  - mainNavbarHeight - dashboardMenuHeight)
  })

  const dateChangeHandler = (date) => {
    setStartDate(date);
    localStorage.setItem(
      "date",
      `{"date": "${date.toISOString().substr(0, 7)}"}`
    );
    dispatch({
      type: "changeDate",
      payload: { date: date.toISOString().substr(0, 7) },
    });
    console.log('coming here 1', date.toISOString().substr(0,7))
  }
  return (
    <div id="outer">
      <div id="dashboard-menu">
        <div
          className={`row align-items-center ${
            darkTheme === true ? "dark-theme-tab" : ""
          } `}
          style={{ margin: "auto" }}
        >
          <div className="col mt-1 mb-1">
            <ul
              className={`nav nav-tabs ${
                darkTheme === true ? "border-bottom-dark" : ""
              }`}
            >
              <li className="nav-sm-item ml-1">
                <a
                  className={`nav-link ${
                    activeReport[0] && darkTheme === false ? "active" : ""
                  } ${darkTheme === true ? "text-light" : "text-dark"}${
                    activeReport[0] && darkTheme === true ? " dark-active" : ""
                  }
                `}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    loadDashboard(
                      currentAccountDetail.M.summary.S,
                      "summary",
                      e
                    );
                  }}
                  style={{ fontSize: "16px", padding: "3px 5px" }}
                >
                  Home
                </a>
              </li>
              <li className="nav-sm-item ml-1">
                <a
                  className={`nav-link  ${
                    activeReport[1] && darkTheme === false ? "active" : ""
                  } ${darkTheme === true ? "text-light" : "text-dark"} 
                ${activeReport[1] && darkTheme === true ? " dark-active" : ""}
                `}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    loadDashboard(currentAccountDetail.M.report.S, "report");
                  }}
                  style={{ fontSize: "16px", padding: "3px 5px" }}
                >
                  Monthly Report
                </a>
              </li>
              {/* <li className="nav-sm-item ml-1">
                <a
                  className={`nav-link  ${
                    activeReport[2] && darkTheme === false ? "active" : ""
                  } ${darkTheme === true ? "text-light" : "text-dark"}${
                    activeReport[2] && darkTheme === true ? " dark-active" : ""
                  }`}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    loadDashboard(currentAccountDetail.M.summary.S, "savings");
                  }}
                  style={{ fontSize: "16px", padding: "3px 5px" }}
                >
                  Savings Tracker
                </a>     
              </li> */}
            </ul>
          </div>
          <div
            // className={`col-lg-2 text-right d-none d-lg-block ${
            //   darkTheme === true ? " text-light bg-dark" : "text-dark"
            // }`}
          >
            <div style={{ fontSize: "13px", color: "white", paddingRight:"20px" }}>Powered By 1Cloudhub</div>
          </div>
        </div>
        {activeReport[1] === true ? (
          <div
            className={`row ${
              true === true ? "dark-theme-tab" : "text-dark"
            }`}
            style={{ margin: "auto" }}
          >
            <div className="col" style={{ marginLeft: "4px" }}>
              <div style={{ fontSize: "14px", padding: "3px 5px" }}>
                <label style={{ color: "white", marginRight: "2px" }}>
                  Month:
                </label>
                <DatePicker
                  // selected = {new Date(Date.parse(minMaxDate.maxDate))}
                  selected = {startDate !==  '' ? startDate : new Date(Date.parse(minMaxDate.maxDate))}
                  onChange={(date) => dateChangeHandler(date)}
                  value={startDate !==  '' ? startDate : new Date(Date.parse(minMaxDate.maxDate))}
                  dateFormat="MMM-yyyy"
                  showMonthYearPicker
                  popperPlacement="right-start"
                  minDate={new Date(Date.parse(minMaxDate.minDate))}
                  maxDate={new Date(Date.parse(minMaxDate.maxDate))}
                />
                <FontAwesomeIcon
                  icon={faCaretDown}
                  style={{
                    position: "absolute",
                    left: "136px",
                    top: "8px",
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        id="inner-down"
        className={`${darkTheme === true ? "bg-dark" : ""}`}
        style={{ height: `${dashboardHeight}px`, width: "auto" }}
      >
        {showSavingTracker === false ? (
          <iframe
            src={dashboardUrl}
            title="Dashboard"
            width="100%"
            height={`${dashboardHeight}px`}
          />
        ) : (
          // <div
          //   id="dashboardContainer"
          //     width="100%"
          //     height={`${dashboardHeight}px`}
          // ></div>
          <>
            <div className="row" style={{ height: "30px", margin: "auto" }}>
              <div className="col"></div>
            </div>
            <div className="row text-center" style={{ margin: "auto" }}>
              <div className={`col ${darkTheme === true ? "text-light" : ""}`}>
                Please Subscribe to enable this feature
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ViewDashboard
