import React, {useEffect} from 'react'
import { AmplifyAuthenticator, AmplifySignIn, AmplifyTotpSetup, AmplifyConfirmSignIn, 
   AmplifySignUp 
  } from '@aws-amplify/ui-react';
import PageWrapper from '../../Layout/PageWrapper'
import {Row, Col} from 'react-bootstrap'
import {
  // useSelector,
  useDispatch 
} from "react-redux";
import { useHistory } from 'react-router-dom'
import { API, Auth as AmpAuth } from "aws-amplify";
import backendConfig from "../../backendConfig";
import './Auth.css'

const Auth = () => {

  const dispatch = useDispatch();
  let history = useHistory();

  let redirectToAccount = async () => {
    try {
      // const currentUserInfo = await AmpAuth.currentUserInfo();
      // const email = currentUserInfo.username;
      // const name = email.slice(0, email.indexOf('@'))
      // let data = await API.get(
      //   backendConfig.apiName,
      //   backendConfig.paths.getAccounts,
      //   {
      //     queryStringParameters: {
      //       email
      //     },
      //   }
      // );
      // console.log(data.message.Item.Accounts.L);
      // console.log('data',data);
      // dispatch({ type: 'gotAccounts', payload: { accounts: data.message.Item.Accounts.L, name } });
      // history.push("/account/0");
      // window.location.href = window.location.origin + '/account/0'
      window.location.href = window.location.origin + '/consolidated-view'
      console.log("coming here new")
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    //console.log('This is ')
    let funct = async () => {
      try {
        let userdata = await AmpAuth.currentAuthenticatedUser()   
        redirectToAccount()   
      } catch (error) {
        console.log('logged in error: '+ error)
      }
    }
    funct()
  },[])


  const handleAuthStateChange = (signedin) => {
    console.log(signedin)
    if (signedin === 'signedin'){
      dispatch({ type: "login", payload: true })      
      redirectToAccount();      
    }
  }

  return (
    <>
      <PageWrapper>
        <Row className="justify-content-md-center auth">
          <Col className="mt-5">
            <AmplifyAuthenticator usernameAlias="email"  hideDefault={true} handleAuthStateChange={handleAuthStateChange} >

              <AmplifySignIn slot="sign-in"  usernameAlias="email"  hideSignUp />
              {/* <AmplifyConfirmSignIn
                headerText="Costimize - Confirm Sign In Text"
                slot="confirm-sign-in"
                handleAuthStateChange={handleAuthStateChange}
                usernameAlias="email" 
              >
              </AmplifyConfirmSignIn> */}
              {/* <AmplifySignUp
                slot="sign-up"
                usernameAlias="email"
                formFields={[
                  {
                    type: "email",
                    label: "Email Address",
                    placeholder: "Email",
                    required: true,
                  },
                  {
                    type: "password",
                    label: "Password",
                    placeholder: "Password",
                    required: true,
                  },
                ]} 
              /> */}
            </AmplifyAuthenticator>
          </Col>
        </Row>
      </PageWrapper>
    </>
  )
}

export default Auth
