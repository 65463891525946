import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import backendConfig from "../../backendConfig";
import PageWrapper from "../../Layout/PageWrapper";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
require("react-datepicker/dist/react-datepicker.css");

const ViewInstanceUtilization = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const instanceID = params.get("instanceid");
  const accountName = params.get("accountName");
  // const dashboard_id = params.get("dashboard_id");
  // const dispatch = useDispatch();
  const [dashboardUrl, setDashboardUrl] = useState("");
  const [dashboardHeight, setDashboardHeight] = useState(500);
  const date = useSelector((state) => state.DateReducer.date);
  // const [startDate, setStartDate] = useState(new Date().setMonth(6));
  // const minMaxDate = useSelector((state) => state.DateReducer);
  const accounts = useSelector(
    (state) => state.AccountReducer.accounts?.dark?.L || []
  );

  let loadDashboard = async () => {
    let dashboard_id = accounts.find((account) => {
      console.log(account);
      if (account.M.name.S === accountName) {
        return true;
      } else {
        return false;
      }
    });
    dashboard_id = dashboard_id.M.utilizationDashboardID.S;
    if (dashboard_id) {
      let data = await API.get(
        backendConfig.apiName,
        backendConfig.paths.getDashboards,
        {
          queryStringParameters: {
            dashboardId: dashboard_id,
          },
        }
      );
      setDashboardUrl(
        `${data.EmbedUrl}#p.reportMonth=${date}-01 00:00&p.instanceID=${instanceID}`
      );
      let mainNavbarHeight = document.getElementById("main-navbar")
        .offsetHeight;
      let dateBar = document.getElementById("date-bar").offsetHeight;
      setDashboardHeight(window.innerHeight - dateBar - mainNavbarHeight);
    }
  };

  useEffect(() => {
    // console.log(dashboard_id);
    loadDashboard();
  }, [accounts]);
  
  // useEffect(() => {
  //   setStartDate(
  //     new Date(Date.parse(JSON.parse(localStorage.getItem("date")).date))
  //   );
  // }, []);

  // useEffect(() => {
  //   console.log("instanceID", instanceID);
  //   loadDashboard();
  // }, [date]);

  // const dateChangeHandler = (date) => {
  //   setStartDate(date);
  //   dispatch({
  //     type: "changeDate",
  //     payload: { date: date.toISOString().substr(0, 7) },
  //   });
  // };

  return (
    <PageWrapper>
      <div
        id="date-bar"
        className={`row text-light bg-dark`}
        style={{ margin: "auto" }}
      >
        {/* <div className="col" style={{ marginLeft: "4px" }}>
          <div style={{ fontSize: "14px", padding: "3px 5px" }}>
            <label style={{ color: "white", marginRight: "2px" }}>Month:</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => dateChangeHandler(date)}
              dateFormat="MMM-yyyy"
              showMonthYearPicker
              popperPlacement="right-start"
              minDate={new Date(Date.parse(minMaxDate.minDate))}
              maxDate={new Date(Date.parse(minMaxDate.maxDate))}
            />
            <FontAwesomeIcon
              icon={faCaretDown}
              style={{
                position: "absolute",
                left: "136px",
                top: "8px",
              }}
            />
          </div>
        </div> */}
      </div>
      {dashboardUrl !== "" ? (
        <div style={{ height: `${dashboardHeight}px`, width: "auto" }}>
          <iframe
            height={dashboardHeight}
            width="100%"
            src={dashboardUrl}
          ></iframe>
        </div>
      ) : (
        ""
      )}
    </PageWrapper>
  );
};

export default ViewInstanceUtilization;
