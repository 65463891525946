/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:dbe6555b-beca-4303-8f7c-c3a388a2f58a",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_AOncmNBhv",
    "aws_user_pools_web_client_id": "9nbf8tpp46b486uh5pa5qt450",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "restApi",
            "endpoint": "https://4ls6i0jb8j.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        }
    ]
};


export default awsmobile;
