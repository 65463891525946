import React,{useState, useEffect} from 'react'
import PageWrapper from '../../Layout/PageWrapper'
import { useParams } from 'react-router-dom'
import { useSelector } from "react-redux";
import { API } from 'aws-amplify'
import backendConfig from '../../backendConfig'
import ViewDashboard from '../../Views/ViewDashboard'

const Account = () => {  
  const [accountId, setAccountId] = useState(useParams().accountId)
  const accounts = useSelector(state => state.AccountReducer.accounts)
  const [dashboardUrl, setDashboardUrl] = useState(null)
  const [accountName, setAccountName] = useState('')
  const [currentAccountDetail, setCurrentAccountDetail] = useState()
  const [activeReport, setActiveReport] = useState([true, false, false]);
  const [showSavingTracker, setShowSavingTracker] = useState(false)
  const darkTheme = useSelector((state) => state.ThemeReducer.darktheme);
  const date = useSelector((state) => state.DateReducer.date)

  let loadDashboard = async (dashboardId, type) => {
    switch(type){
      case 'summary':
        setActiveReport([true, false, false])
        break
      case 'report':
        setActiveReport([false, true, false])
        break;
      case 'savings':
        setActiveReport([false, false, true])
        break;
    }

    if (type !== 'savings'){
      try {
        setShowSavingTracker(false)
        let data = await API.get(
          backendConfig.apiName,
          backendConfig.paths.getDashboards,
          {
            queryStringParameters: {
              dashboardId
            },
          }
        );
        console.log({ data });
        // setDashboardUrl(data.EmbedUrl);
        console.log('date here', date)
        if (type === "report"){                    
          setDashboardUrl(
            `${data.EmbedUrl}#p.reportMonth=${date}-01 00:00&p.accountName=${currentAccountDetail.M.name.S}&p.cloudPlatform=${currentAccountDetail.M.cloud.S}`
          );
          console.log(
            "dashboard url",
            `${data.EmbedUrl}#p.reportMonth=${date}-01 00:00`
          );
        }else{
          setDashboardUrl(data.EmbedUrl)
        }

      } catch (error) {
        console.log({ error });
      }
    }else{
      setShowSavingTracker(true)
    }
  };

  useEffect(() => {
    console.log("coming here 2");
    let index = activeReport.indexOf(true);
    if (accounts?.dark?.L.length) {
      setLoadDashboard(index);
    }
    },[date])
  // }, [newDate]);
  
  useEffect(() => {
    if(accounts?.dark?.L.length){
      if (darkTheme) {
        setCurrentAccountDetail(accounts.dark.L[accountId]);
        loadDashboard(accounts?.dark.L[accountId].M.summary.S);
        setAccountName(accounts?.dark.L[accountId].M.name.S);
      } else {
        setCurrentAccountDetail(accounts.light.L[accountId]);
        loadDashboard(accounts?.light.L[accountId].M.summary.S);
        setAccountName(accounts?.light.L[accountId].M.name.S);
      }
      console.log('came here')
    }
    console.log(accounts, accountId)
    localStorage.setItem('date',`{"date": "${date}"}`)
  }, [accounts])

  const setLoadDashboard = (index) => {
    console.log('index', index)
    if (darkTheme) {
      if (index === 0) {
        setCurrentAccountDetail(accounts.dark.L[accountId]);
        loadDashboard(accounts?.dark.L[accountId].M.summary.S);
        setAccountName(accounts?.dark.L[accountId].M.name.S);
      } else if (index === 1) {
        setCurrentAccountDetail(accounts.dark.L[accountId]);
        loadDashboard(accounts?.dark.L[accountId].M.report.S, "report");
        setAccountName(accounts?.dark.L[accountId].M.name.S);
      } else if (index === 2) {
        // setCurrentAccountDetail(accounts.dark.L[accountId]);
        // loadDashboard(accounts?.dark.L[accountId].M.summary.S);
        // setAccountName(accounts?.dark.L[accountId].M.name.S);
      }
    } else {
      if (index === 0) {
        setCurrentAccountDetail(accounts.light.L[accountId]);
        loadDashboard(accounts?.light.L[accountId].M.summary.S);
        setAccountName(accounts?.light.L[accountId].M.name.S);
      } else if (index === 1) {
        setCurrentAccountDetail(accounts.light.L[accountId]);
        loadDashboard(accounts?.light.L[accountId].M.report.S, "report");
        setAccountName(accounts?.light.L[accountId].M.name.S);
      } else if (index === 2) {
        // setCurrentAccountDetail(accounts.light.L[accountId]);
        // loadDashboard(accounts?.light.L[accountId].M.report.S);
        // setAccountName(accounts?.light.L[accountId].M.name.S);
      }
    }
  }

  useEffect(() => {
    console.log('current theme is',darkTheme);
    let index = activeReport.indexOf(true)
    if(accounts?.dark?.L.length){
      setLoadDashboard(index)
    }
  },[darkTheme]);

  return (
    <PageWrapper>
      <ViewDashboard
        activeReport={activeReport}
        loadDashboard={loadDashboard}
        currentAccountDetail={currentAccountDetail}
        dashboardUrl={dashboardUrl}
        accountName={accountName}
        showSavingTracker={showSavingTracker}
        darkTheme={darkTheme}
        date={date}
      />
    </PageWrapper>
  );
}

export default Account
