import React, {useState, useEffect} from 'react'
import PageWrapper from '../../Layout/PageWrapper'
import {API} from 'aws-amplify'
import backendConfig from '../../backendConfig'
import {Row, Col} from 'react-bootstrap'

const ViewDashboard = (props) => {
  //need to get this from environment variable instead of hardcoding it
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get('id');
  const [dashboardId, setDashboardId] = useState(id)
  const [dashboardUrl, setDashboardUrl] = useState(null)

  useEffect(() => {
    let func = async () => {
      try {
        let data = await API.get(backendConfig.apiName, backendConfig.paths.getDashboards , {
          queryStringParameters: {
            dashboardId
          },
        })
        console.log({ data })
        setDashboardUrl(data.EmbedUrl)
          // setDashboard({ EmbedUrl: data.EmbedUrl })
          // console.log(data.EmbedUrl)
      } catch (error) {
        console.log({ error })
      }
    }
    func()
  },[])
  
  // useEffect(() => {
  //   console.log(dashboardUrl)
  // }, [dashboardUrl])

  return (
    <PageWrapper>
      <div className="row">
        <div className="col mt-1 ml-1 mb-1">
          <div className="btn-group btn-group-sm" role="group" aria-label="...">
            <button type="button" className="btn btn-secondary">
              Summary
            </button>
            <button type="button" className="btn btn-secondary">
              Report
            </button>
          </div>
        </div>
        <div className="col"></div>
        <div className="col"></div>
      </div>
      <Row>
        <Col>
          <iframe
            src={dashboardUrl}
            title="description"
            width="100%"
            height="550"
          />
        </Col>
      </Row>
    </PageWrapper>
  );
}

export default ViewDashboard
